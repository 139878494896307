import Sust from '../../../assets/sust.jpeg'
import classes from "./Sustainable.module.css";

export default function Sustainable() {
    return (
        <div className="container-fluid min-vh-100">
            <div className="row">
                <div className="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center">
                    <img className={classes.sust} src={Sust} alt="" />
                </div>
                <div className="col-12 col-md-6">
                    <div className="d-flex flex-column align-items-center justify-content-evenly min-vh-100">
                        <div className={classes.sustainableMission}>
                            <h2>OUR MISSION</h2>
                            <p>Ramael's team believes in sustainability which considers to be an indispensable value to be able to face the concept of luxury.</p>
                        </div>
                        <div className={classes.sustainableFabrics}>
                            <h2>FABRICS</h2>
                            <p>Ramael's work is based on the search of regenerated materials that come from Italian companies, which aim to pursue the value of sustainability by obtaining important certifications.</p>
                        </div>
                        <p className="text-center">Ramael wants to be responsible</p>
                    </div>
                </div>
            </div>
        </div>

    )
}