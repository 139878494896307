import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Mobile from './components/UI/Mobile/Mobile';
import Navbar from './components/UI/Navbar/Navbar';
import Home from './components/views/Home/Home';
// import Man from './components/views/Man/Man';
// import Woman from './components/views/Woman/Woman';
// import Lookbook from './components/views/Lookbook/Lookbook';
import About from './components/views/About/About';
import Sustainability from './components/views/Sustainable/Sustainable';
import Stockists from './components/views/Stocklist/Stocklist';
import Contact from './components/views/Contact/Contact';
import { useEffect, useState } from 'react';
import Footer from './components/UI/Footer/Footer';

function App() {

  const [mobile, setMobile] = useState(window.innerWidth < 900 ? true : false)

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 992) {
        setMobile(true)
      } else {
        setMobile(false)
      }
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [mobile])

  return (
    <Router>
      {
        mobile ? <Mobile /> : <Navbar />
      }
      <Switch>
        {/* <Route path="/woman/:slug">
          <Woman />
        </Route>
        <Route path="/man/:slug">
          <Man />
        </Route>
        <Route path="/lookbook/:slug">
          <Lookbook />
        </Route> */}
        <Route path="/about">
          <About mobile={mobile} />
        </Route>
        <Route path="/sustainability">
          <Sustainability />
        </Route>
        <Route path="/stockists">
          <Stockists />
        </Route>
        <Route path="/contact">
          <Contact mobile={mobile} />
        </Route>
        <Route path="/">
          <Home mobile={mobile} />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
