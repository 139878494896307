import classes from './Mobile.module.css'
import { Link, useLocation } from 'react-router-dom'
import Logo from './../../../assets/logo.png'
// import AccordionRamael from '../AccordionRamael/AccordionRamael'
// import { lookbook, man, woman } from '../../../Collections/Collections'
// import { useState } from 'react'

export default function Mobile() {

    const openMenu = () => {
        document.querySelector('body').classList.toggle(classes['open'])
    }

    const clickLink = () => {
        setTimeout(() => {
            document.querySelector('body').classList.toggle(classes['open'])
        }, 300);
    }

    const location = useLocation().pathname

    // const [expanded, setExpanded] = useState(null)

    return (
        <div className={location === '/' ? classes.navContainerHome : classes.navContainer}>
            <button
                className={classes["menu-toggle"] + " " + classes["menuBtnMobile"]}
                onClick={openMenu}
            ></button>
            <Link to='/'>
                <img className={classes['mark'] + ' mt-3'} src={Logo} alt="mark" />
            </Link>
            <nav>
                <div className={classes["menu"]}>
                    <Link
                        className='text-decoration-none'
                        onClick={clickLink}
                        to='/'
                    >Home</Link>
                    {/* <div className={classes.accordionWrapper}>
                        <AccordionRamael
                            mobile={true}
                            id='Woman'
                            collections={woman}
                            clickLink={clickLink}
                            expanded={expanded}
                            setExpanded={setExpanded}
                        />
                    </div>
                    <div className={classes.accordionWrapper}>
                        <AccordionRamael
                            mobile={true}
                            id='Man'
                            collections={man}
                            clickLink={clickLink}
                            expanded={expanded}
                            setExpanded={setExpanded}
                        />
                    </div>
                    <div className={classes.accordionWrapper}>
                        <AccordionRamael
                            mobile={true}
                            id='Lookbook'
                            collections={lookbook}
                            clickLink={clickLink}
                            expanded={expanded}
                            setExpanded={setExpanded}
                        />
                    </div> */}
                    <Link
                        className='text-decoration-none'
                        onClick={clickLink}
                        to='/about'
                    >About</Link>
                    <Link
                        className='text-decoration-none'
                        onClick={clickLink}
                        to='/sustainability'
                    >Sustainability</Link>
                    <Link
                        className='text-decoration-none'
                        onClick={clickLink}
                        to='/stockists'
                    >Stockists</Link>
                    <Link
                        className='text-decoration-none'
                        onClick={clickLink}
                        to='/contact'
                    >Contact</Link>
                </div>
            </nav>
        </div>
    )
}