import Insta from './../../../assets/instagram.png'

export default function Contact({ mobile }) {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className={"col-12 min-vh-100 " + (mobile ? "text-center pt-5" : "d-flex flex-column justify-content-center align-items-center")}>
                    <p className="text-center mb-0">General Enquiries: info@ramael.eu</p>
                    <p className="text-center mb-0">Wholesale: sales@ramael.eu</p>
                    <a className='text-decoration-none d-contents' href="https://www.instagram.com/ramael_official" target="_blank">
                        <img className='insta pt-3' src={Insta} alt="" />
                    </a>
                </div>
            </div>
        </div>
    )
}