import Manifesto from './../../../assets/about/005735.jpg'

export default function About({mobile}) {
    return (
        <div className="container-fluid min-vh-100">
            <div className="row">
                <div className={"col-12 col-md-6 " + (mobile ? "d-block px-about" : "d-flex flex-column justify-content-center min-vh-100 px-about")}>
                    <p className="lead">
                        MANIFESTO
                    </p>
                    <p>
                        RAMAEL is a collective inspiration
                        translates in a recycled closet that mixes a
                        new punk language, maturity and luxury
                        with oversized and genderless silhouettes.
                        Resposability is the ethical code and
                        project’s soul.
                    </p>
                </div>
                <div className={"col-12 col-md-6 " + (mobile ? "d-block text-center my-5" : "d-flex flex-column justify-content-center align-items-center")}>
                    <img className='manifesto' src={Manifesto} alt="" />
                </div>
            </div>
        </div >
    )
}