import classes from './Home.module.css'
import video from './../../../assets/home_video.mp4'

export default function Home({ mobile }) {

    // function importAll(r) {
    //     let images = [];
    //     r.keys().map(item => images[item.replace('./', '')] = r(item));
    //     return images;
    // }

    // const images = importAll(require.context('./../../../assets/home', false, /\.jpg$/));

    return (
        <div className={classes['imgsContainer']}>
            <div className={mobile ? classes.imgsContainerFirstMobile : classes.imgsContainerFirst}>
                {
                    // images['0001.jpg'] && <img className={classes['home__img--0']} src={images['0001.jpg']} alt={images['0001.jpg']} />
                    <video style={!mobile ? { width: '180%', marginTop: '400px' } : { marginTop: '0px', width: "400%" }} playsInline loop autoPlay muted>
                        <source src={video} type="video/mp4" />
                    </video>
                }
            </div>
        </div>
    )
}