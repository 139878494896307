import classes from './Navbar.module.css'
import Logo from '../../../assets/logo.png'
// import AccordionRamael from '../../UI/AccordionRamael/AccordionRamael'
// import { lookbook, man, woman } from '../../../Collections/Collections'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react';


export default function Navbar() {

    // const [expanded, setExpanded] = useState(null)

    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };
        handleScroll();

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className={classes.navbar}>
            <Link className='text-decoration-none' to='/'>
                <img src={Logo}
                    alt="ramael-logo"
                    className={scrollY >= 80 ? classes.logoScroll : classes.logo} />
            </Link>
            <div className={classes.topbar}>
                {/* <AccordionRamael
                    mobile={false}
                    id='Woman'
                    expanded={expanded}
                    setExpanded={setExpanded}
                    collections={woman}
                />
                <AccordionRamael
                    mobile={false}
                    id='Man'
                    expanded={expanded}
                    collections={man}
                    setExpanded={setExpanded}
                />
                <AccordionRamael
                    mobile={false}
                    id='Lookbook'
                    expanded={expanded}
                    collections={lookbook}
                    setExpanded={setExpanded}
                /> */}
                <Link className='text-decoration-none' to='/about'>
                    <button
                        // onClick={() => setExpanded(null)}
                        className={classes.navbarBtn + ' btn'}
                    >About</button>
                </Link>
                <Link className='text-decoration-none' to='/sustainability'>
                    <button
                        // onClick={() => setExpanded(null)}
                        className={classes.navbarBtn + ' btn'}
                    >Sustainability</button>
                </Link>
                <Link className='text-decoration-none' to='/stockists'>
                    <button
                        // onClick={() => setExpanded(null)}
                        className={classes.navbarBtn + ' btn'}
                    >Stockists</button>
                </Link>
                <Link className='text-decoration-none' to='/contact'>
                    <button
                        // onClick={() => setExpanded(null)}
                        className={classes.navbarBtn + ' btn'}
                    >Contact</button>
                </Link>
            </div>
        </div>
    )
}