export default function Stocklist() {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 d-flex flex-column justify-content-end">
                    <div className="mt-custom">
                        <p className="text-center mb-0 fw-bolder">EUROPE</p>
                        <p className="text-center mb-0 small">Italy</p>
                        <p className="text-center mb-0 small">10 CORSO COMO</p>
                        <p className="text-center mb-0 small">ANTONIA</p>
                        <p className="text-center mb-0 small">BASE BLU</p>
                        <p className="text-center mb-0 small">BRUNA ROSSO</p>
                        <p className="text-center mb-0 small">COSE</p>
                        <p className="text-center mb-0 small">DI VINCENZO</p>
                        <p className="text-center mb-0 small">DOLCI TRAME</p>
                        <p className="text-center mb-0 small">FIACCHINI</p>
                        <p className="text-center mb-0 small">FOLLI FOLLIE</p>
                        <p className="text-center mb-0 small">FR</p>
                        <p className="text-center mb-0 small">G&B</p>
                        <p className="text-center mb-0 small">LATTUADA</p>
                        <p className="text-center mb-0 small">MODES</p>
                        <p className="text-center mb-0 small">ONE OFF</p>
                        <p className="text-center mb-0 small">PAPINI</p>
                        <p className="text-center mb-0 small">POZZILEI</p>
                        <p className="text-center mb-0 small">QUORUM</p>
                        <p className="text-center mb-0 small">RATTI</p>
                        <p className="text-center mb-0 small">SPAZIO PRITELLI</p>
                        <p className="text-center mb-0 small">SPOON</p>
                        <p className="text-center mb-0 small">SUGAR</p>
                        <p className="text-center mb-0 small">VINICIO</p>
                        <p className="text-center mb-0 small mt-2">Switzerland</p>
                        <p className="text-center mb-0 small">MODA IN</p>
                        <p className="text-center mt-2 mb-0 fw-bolder">RUSSIA</p>
                        <p className="text-center small mb-0">TSUM</p>
                        <p className="text-center mt-2 mb-0 fw-bolder">AMERICA</p>
                        <p className="text-center small mb-0">Usa</p>
                        <p className="text-center small mb-0">IKRAM</p>
                    </div>
                </div>
            </div>
        </div>
    )
}